import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'BillingService',

  deleteAccountingInformation(facilityReferenceNo, year, month) {
    return HttpInterceptor.httpClient().delete('billing/' + facilityReferenceNo + '/delete/' + year + '/' + month);
  },

  getAccountingForYearAndMonth(facilityReferenceNo, year, month) {
    return HttpInterceptor.httpClient().get('billing/' + facilityReferenceNo + '/accountings/' + year + '/' + month);
  },

  getSepaXML4YearAndMonth(facilityReferenceNo, year, month, childIds) {
    let config = {
      headers: {
        'content-type': 'application/json',
        'accept': 'application/xml',
        'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token'),
      }
    };

    return HttpInterceptor.httpClient().post('billing/' + facilityReferenceNo + '/sepa/' + year + '/' + month, childIds, config);
  },

  getAccountInfoExcelSheet(facilityReferenceNo) {
    return HttpInterceptor.httpClient().get('billing/' + facilityReferenceNo + '/account/info', {responseType: 'arraybuffer'});
  },

  getAccountingInformation4Year(facilityReferenceNo, year) {
    return HttpInterceptor.httpClient().get('billing/' + facilityReferenceNo + '/summary/' + year);
  },

  getListOfClaims(facilityReferenceNo, year) {
    return HttpInterceptor.httpClient().get('billing/' + facilityReferenceNo + '/opl/' + year);
  },

  createAccounting4YearAndMonth(facilityReferenceNo, year, month, billingDate, preview) {
    return HttpInterceptor.httpClient().put('billing/' + facilityReferenceNo + '/create/' + year + '/' + month + '?preview=' + preview, billingDate);
  },

  setAccountingState(accountingId, state) {
    return HttpInterceptor.httpClient().put('billing/accounting/' + accountingId + '/state', state);
  },

  updateBillingItem(item) {
    return HttpInterceptor.httpClient().put('billing/update', item);
  },
}
