import {Doughnut, mixins} from 'vue-chartjs';

const {reactiveProp} = mixins;

export default {
  name: 'DoughnutChart',
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    options: {
      required: true
    },
    chartData: {
      required: true
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options)
  },

  watch: {
    options() {
      this.$data._chart.options = this.options;
      this.$data._chart.data = this.chartData;
      this.$data._chart.update();
    },
    chartData() {
      this.$data._chart.options = this.options;
      this.$data._chart.data = this.chartData;
      this.$data._chart.update();
    },
  },
}
