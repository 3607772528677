<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" id="createNewChildDialog" v-if="facilityReferenceNumber && facilityName" :md-active.sync="show"
               @md-closed="closeDialog">
      <md-dialog-title>
        <div class="grid-x grid-padding-x">
            <div class="cell large-7 medium-8">
              <i class="fi-euro"></i>&nbsp;&nbsp;&nbsp;Forderungsliste <b style="color: orangered">{{year}}</b> für <b style="color: orangered">{{facilityName}}</b>
            </div>
            <div class="cell large-2 medium-1 hide-for-small-only" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-3 medium-3 hide-for-small-only text-right">
              <button class="button success" style="margin-left: 1rem;" @click="closeDialog" type="button" :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Schliessen
              </button>
            </div>
          </div>
        <md-progress-bar md-mode="indeterminate" v-if="sending"/>
      </md-dialog-title>

      <md-dialog-content id="dialogContentId">
        <div class="grid-x">
          <div class="cell" v-if="listOfClaims">
            <md-table id="claimsTableId" v-model="searchedListOfClaims" md-sort="fullname"
                      md-sort-order="asc" md-card md-fixed-header
                      @md-selected="onSelectClaim">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x">
                  <div class="cell large-5 medium-12 small-12">
                    <p class="md-title ai-number" style="margin-left: 0">Offen: {{searchedListOfClaims.length}} Rechnung<span v-if="searchedListOfClaims.length !== 1">en</span></p>
                  </div>
                  <div class="cell large-3 medium-6 small-6">
                    <p class="md-title ai-number">Summe <b>{{sumOpenClaimAmounts | currency}}</b></p>
                  </div>
                  <div class="cell large-4 medium-6 small-6">
                    <md-field md-clearable class="md-toolbar-section-end">
                      <md-input placeholder="Suche nach Kind ..." v-model="searchClaim"
                                @input="searchOnClaimsTable"/>
                    </md-field>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-empty-state v-if="searchClaim"
                                    md-label="Keine Rechnung gefunden"
                                    :md-description="`Keine Rechnung mit dem Suchbegriff '${searchClaim}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
              </md-table-empty-state>

              <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-auto-select style="padding: 0">
                <md-table-cell style="padding: 0;" md-label="Kind" md-sort-by="fullname">

                  <md-card style="background: transparent; padding: 0; box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0,0,0,0), 0 1px 5px 0 rgba(0,0,0,0);">
                    <md-card-expand style="background: transparent; padding: 0;">
                      <md-card-actions md-alignment="left" style="background: transparent; padding: 0;">
                        <md-card-expand-trigger>
                          <md-button class="md-icon-button" style="padding: 0;">
                            <md-icon>keyboard_arrow_down</md-icon>
                            <md-tooltip>Details anzeigen</md-tooltip>
                          </md-button>
                        </md-card-expand-trigger>
                        <div>
                          <ImageView :imageId="item.childImageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;{{ item.fullname }}
                        </div>
                      </md-card-actions>
                      <md-card-expand-content>
                        <md-card-content style="padding-bottom: 8px;">
                          <b>Kontoinhaber</b><br>
                          <BankAccountView :bankAccount="item.accountInfo" :raw="true"/>
                          <br>
                          <b>Zahlungsweise:</b>&nbsp;<span>{{ labels.paymentMethod[item.paymentMethod] }}</span>
                          <span
                              v-if="item.paymentMethod === 'SEPA'"> ({{labels.mandateType[item.accountInfo.mandateType] }})</span><br>
                          <b>Zahlungsinterval:</b>&nbsp;<span>{{ labels.paymentInterval[item.paymentInterval] }}</span><br><br>
                          <b>{{item.billingItems.length}}</b> Position<span v-if="item.billingItems.length > 1">en</span><br>
                          <span v-for="(bi, index) of item.billingItems" :key="index">
                    {{index + 1}})&nbsp;
                    {{bi.billingDate | moment("DD.MM.YYYY")}}&nbsp;
                    {{labels.feeType[bi.feeType]}}&nbsp;
                    {{bi.amount | currency}}&nbsp;
                    <b :class="item.state">{{ labels.accountingState[bi.state] }}</b>
                    <br></span><br>
                          <b>Gesamtbetrag:</b>&nbsp;<span>{{ item.totalAmount | currency }}</span>&nbsp;
                          <b :class="item.state">{{ labels.accountingState[item.state] }}</b>
                          <br>
                          <b>Gesamtnachlass:</b>&nbsp;<span>{{ item.totalDiscount | currency }}</span><br><br>
                          <b>Zahlungsziel:</b>&nbsp;<span>{{ item.billingDate | moment("DD.MM.YYYY") }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        </md-card-content>
                      </md-card-expand-content>
                    </md-card-expand>
                  </md-card>

                </md-table-cell>
                <md-table-cell md-label="Zahlungsziel" md-sort-by="billingDate">{{ item.billingDate | moment('DD.MM.YYYY') }}
                </md-table-cell>
                <md-table-cell md-label="Bereich" md-sort-by="divisionType">{{ labels.divisiontype[item.divisionType] }}
                </md-table-cell>
                <md-table-cell md-label="Positionen" md-sort-by="billingItems.length">{{ item.billingItems.length }}
                </md-table-cell>
                <md-table-cell md-label="Gesamtbetrag" md-sort-by="totalAmount">{{ item.totalAmount | currency }}
                </md-table-cell>
                <md-table-cell md-label="Intervall" md-sort-by="paymentInterval">{{ labels.paymentInterval[item.paymentInterval] }}
                </md-table-cell>
                <md-table-cell md-label="Methode" md-sort-by="paymentMethod">{{ labels.paymentMethod[item.paymentMethod] }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
        <br>
      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>

  import SessionTime from '../animations/SessionTime';
  import ImageView from '../../components/views/ImageView';
  import BankAccountView from '../../components/views/BankAccountView';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchBillByChildName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.fullname).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'ListOfClaimsDialog',

    components: {
      SessionTime,
      ImageView,
      BankAccountView,
    },

    props: {
      facilityReferenceNumber: {
        required: true,
      },
      facilityName: {
        required: true,
      },
      listOfClaims: {
        required: true,
      },
      year: {
        required: true,
      },
      showDialog: {
        required: true,
      }
    },

    watch: {
      listOfClaims() {
        this.searchOnClaimsTable();
      },
      showDialog() {
        this.show = this.showDialog;
      },
    },

    created() {
    },

    data: () => ({
      sending: false,
      show: false,

      searchedListOfClaims: [],
      searchClaim: '',
    }),

    methods: {

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      closeDialog() {
        this.$emit("closeListOfClaimsDialog");
      },

      onSelectClaim() {

      },

      searchOnClaimsTable() {
        this.searchedListOfClaims = searchBillByChildName(this.listOfClaims, this.searchClaim);
      },
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      labels() {
        return this.$store.getters.labels
      },

      organization() {
        return this.$store.getters.organization;
      },

      sumOpenClaimAmounts() {
        let sum = 0;
        this.searchedListOfClaims.forEach(claim => sum += claim.totalAmount);
        return sum;
      },
    },
  };
</script>

<style scoped>

  .md-dialog /deep/.md-dialog-container {
    width: 1200px;
    min-width: 95%;
  }

  .md-dialog-content {
    padding: 0 24px 6px;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

</style>