<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.financeModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3><img width="35px" src="../assets/icons/calculator.png"/>&nbsp;&nbsp;&nbsp;Abrechnungen</h3>
        </div>
      </div>

      <div v-if="facilitiesNotExists" class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Abrechnungen"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell large-6">
          <md-table v-if="searched" v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                    @md-selected="onSelect" :md-selected-value.sync="selectedRows" style="height: 942px;">
            <md-table-toolbar>
              <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                <div class="cell large-4 hide-for-medium-only hide-for-small-only">
                  <p class="md-title ai-number" style="margin-left: 0;">{{ searched.length }} Monate verfügbar</p>
                </div>
                <div class="cell large-2 medium-0 small-0">
                  <div v-if="sending">
                    <vue-simple-spinner></vue-simple-spinner>
                  </div>
                </div>
                <div v-if="!isSage && availableFacilities && availableFacilities.length > 0"
                     class="cell large-6 medium-12 small-12">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber"
                               :disabled="sending || availableFacilities.length <= 1"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber" :key="facility.id">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>

                <div class="cell large-2 medium-12 small-12">
                  <md-field>
                    <label>Jahr</label>
                    <md-select v-model="selectedYear" :disabled="sending" @md-selected="onBillingYear">
                      <md-option v-for="year of billingYears" :value="year" :key="year">
                        {{ year }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-10 medium-12 small-12">
                  <BarChart v-if="billingBarChartData && billingBarChartOptions"
                            :chartData="billingBarChartData" style="max-height: 150px"
                            :options="billingBarChartOptions"></BarChart>
                </div>

                <div class="cell medium-8 large-6">
                  <button class="button success expanded" :disabled="sending" @click="onOpenListOfClaimsDialog">
                    <i class="fi-euro"></i>&nbsp;&nbsp;&nbsp;<span>Forderungsliste<md-tooltip>Übersicht aller offenen Rechnungen anzeigen</md-tooltip></span>
                  </button>
                </div>
                <div class="cell medium-4 large-6 hide-for-small-only"></div>
              </div>
            </md-table-toolbar>

            <md-table-row :id="item.month" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                          md-selectable="single">
              <md-table-cell md-label="Monat" md-sort-by="month">
                {{ getMonthStr(item.month) }}
              </md-table-cell>
              <md-table-cell md-label="offen" md-sort-by="openItems">
                {{ item.openItems }}
              </md-table-cell>
              <md-table-cell md-label="bezahlt" md-sort-by="payedItems">
                {{ item.payedItems }}
              </md-table-cell>
              <md-table-cell md-label="Summe gesamt" md-sort-by="totalMonthlyAmount">
                {{ item.totalMonthlyAmount | currency }}
              </md-table-cell>
              <md-table-cell md-label="Summe offen" md-sort-by="openAmount">
                {{ item.openAmount | currency }}
              </md-table-cell>
              <md-tooltip style="font-size: medium;" md-direction="left" md-delay="250">
                <b>Abrechnungszeitraum</b><br>
                <span>{{ getMonthStr(item.month) }}</span><br><br>
                <span v-if="item.billingDate">
                  <b>Abrechnung zum</b><br>
                  <span>{{ item.billingDate | moment("DD.MM.YYYY") }}</span><br><br>
                  <b>Anzahl Kinder:</b><br>
                  <span>Maximal:</span>&nbsp;<b>{{ item.maxChildren }}</b><br>
                  <span>mit Buchungszeit:</span>&nbsp;<b>{{ item.childrenWithBookingTime }}</b><br>
                  <span>mit Gebühren:</span>&nbsp;<b>{{ item.childrenWithFees }}</b><br><br>
                  <b>Anzahl Positionen:</b><br>
                  <span>insgesamt:</span>&nbsp;<b>{{ item.totalItems }}</b><br>
                  <span>{{ labels.billingState['OPEN'] }}:</span>&nbsp;<b class="OPEN">{{ item.openItems }}</b><br>
                  <span>{{ labels.billingState['PAYED'] }}:</span>&nbsp;<b class="PAYED">{{ item.payedItems }}</b><br>
                  <span>{{ labels.billingState['UNPAYED'] }}:</span>&nbsp;<b
                    class="UNPAYED">{{ item.unpayedItems }}</b><br>
                  <span>{{ labels.billingState['CANCELED'] }}:</span>&nbsp;<b
                    class="CANCELED">{{ item.canceledItems }}</b><br>
                </span>
                <span v-else>
                  <b style="color: orangered;">Noch keine Abrechnung erstellt.</b><br>
                </span>
              </md-tooltip>
            </md-table-row>
          </md-table>
        </div>
        <div class="cell large-6">
          <md-card id="accountingCardId" style="height: 942px;">
            <md-card-header v-if="selectedBill">
              <div class="md-layout">
                <div class="md-layout-item md-size-90">
                  <p>
                    <span class="md-title">Abrechnung:&nbsp;</span>
                    <span class="bill-title">{{ getMonthStr(selectedBill.month) }}&nbsp;{{ selectedYear }}&nbsp;</span>
                    <span class="bill-sub-title">{{ selectedFacilityName }}&nbsp;</span>
                  </p>
                </div>
                <div class="md-layout-item md-size-10 text-center">
                  <md-button class="md-icon-button md-raised md-primary" @click="$router.push('/Drucken/Abrechnung')">
                    <md-icon>print</md-icon>
                    <md-tooltip md-direction="bottom" style="font-size: medium;">
                      Abrechnung für <b>{{ getMonthStr(selectedBill.month) }} {{ selectedYear }}</b> jetzt drucken
                    </md-tooltip>
                  </md-button>
                </div>
              </div>
              <div v-if="!selectedBill.accountingExists && !previewActive">
                <div v-if="selectedBill.creationOfAccountingPossible">
                  <br>
                  <h5><i>Noch keine Abrechnung vorhanden.</i></h5>
                  <br>
                  <div class="md-layout">
                    <div class="md-layout-item" style="padding-right: 1rem;">
                      <md-datepicker id="billing-date-dp" :md-debounce="10000" :md-open-on-focus="false" md-immediately
                                     v-model="billingDate" :disabled="sending"
                                     :class="getValidationClass('billingDate')"
                                     @md-closed="onCloseBillingDatePicker" @md-changed="validateBillingDate">
                        <label>Abrechnungsdatum</label>
                        <span class="md-error" v-if="$v.billingDate.$model === 'Invalid date'">Abrechnungsdatum ist notwendig</span>
                      </md-datepicker>
                    </div>
                    <div v-if="!sending" class="md-layout-item" style="padding-top: 1rem; padding-left: 1rem;">
                      <button @click="onCreateBilling" class="button success expanded"
                              :disabled="sending || $v.$invalid || !(isFacility || isTreasurer || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                        <img width="14px" src="../assets/icons/calculator.png"/>
                        &nbsp;&nbsp;&nbsp;Abrechnung erstellen&nbsp;
                        <md-tooltip>Die Abrechnung wird erstellt, kann aber danach nochmal gelöscht werden<br>sofern
                          kein Status eines Zahlungsposten verändert wurde.
                        </md-tooltip>
                      </button>
                    </div>
                    <div v-else class="md-layout-item">
                      <vue-simple-spinner></vue-simple-spinner>
                    </div>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <button @click="onPreviewBilling" class="button expanded" :disabled="sending || !(isFacility || isTreasurer || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                        <md-icon style="color: black; height: 13px; font-size: 1.3rem!important;">visibility</md-icon>&nbsp;&nbsp;&nbsp;Vorschau
                        Abrechnung &nbsp;
                        <md-tooltip>Die Abrechnung wird erstellt, kann aber nicht bearbeitet werden.<br>Die angezeigten
                          Daten dienen nur einer Vorabkontrolle.
                        </md-tooltip>
                      </button>
                    </div>
                    <div class="md-layout-item"></div>
                  </div>
                </div>
                <div v-else>
                  <br>
                  <h5><i>Abrechnung kann nicht erstellt werden.</i></h5>
                  <br>
                  <h4 class="bill-sub-title">Gründe:</h4>
                  <p v-for="(reason, index) of selectedBill.creationDeniedReasons" :key="reason">{{ index + 1 }})
                    {{ reason }}</p>
                </div>
              </div>
            </md-card-header>

            <md-card-header v-else>
              <h5><p><i>Bitte wählen Sie einen Monat aus</i></p></h5>
            </md-card-header>

            <md-card-content id="groupChildrenTableId"
                             v-if="selectedBill && (selectedBill.accountingExists || previewActive)"
                             style="height: 750px;">
              <md-table id="searchedAccountingTableId" v-model="searchedAccounting" md-sort="fullname"
                        md-sort-order="asc" md-card md-fixed-header
                        @md-selected="onSelectAccountings" style="height: 720px;">
                <md-table-toolbar>
                  <div class="md-toolbar-section-start grid-x grid-padding-x">
                    <div class="cell large-5 hide-for-medium-only hide-for-small-only">
                      <p class="md-title ai-number" style="margin-left: 0;">Abgerechnet: {{ searchedAccounting.length }}
                        Kinder</p>
                      <h1 v-if="previewActive" class="md-sub-title">Vorschau</h1>
                      <h1 v-else class="md-sub-title">zum {{ selectedBill.billingDate | moment('DD.MM.YYYY') }}</h1>
                    </div>
                    <div class="cell large-2 hide-for-medium-only hide-for-small-only">
                      <md-button v-if="!previewActive" class="md-icon-button md-raised md-accent" @click="deleteAllAccountingDialog"
                                 :disabled="sending || !(isFacility || isTreasurer || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                        <md-icon>delete</md-icon>
                        <md-tooltip md-direction="left">Abrechnung <b>{{ getMonthStr(selectedBill.month) }}
                          {{ selectedYear }}</b>
                          löschen
                        </md-tooltip>
                      </md-button>
                    </div>
                    <div class="cell large-5 medium-12 small-12">
                      <md-field md-clearable class="md-toolbar-section-end">
                        <md-input placeholder="Suche nach Kind ..." v-model="searchBill"
                                  @input="searchOnAccountingTable"/>
                      </md-field>
                    </div>

                    <div class="cell large-12 medium-12 small-12">
                      <DoughnutChart v-if="amountsPerTypeDoughnutChartData && amountsPerTypeDoughnutChartOptions"
                                     :chartData="amountsPerTypeDoughnutChartData" style="max-height: 120px"
                                     :options="amountsPerTypeDoughnutChartOptions"></DoughnutChart>
                    </div>

                    <div class="cell">
                      <div class="grid-x grid-padding-x grid-padding-y">
                        <div class="cell hide-for-small-only">
                          <table class="hover">
                            <thead>
                            <tr>
                              <th class="text-right slim-row">Status</th>
                              <th v-for="method of labels.paymentMethods" :key="method" class="text-right slim-row"
                                  v-if="selectedPaymentMethods.includes(method)">{{ labels.paymentMethod[method] }}
                              </th>
                              <th class="text-right slim-row">Summe</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="state of labels.accountingStates" :key="state" :style="getAmountStyle(state)">
                              <td v-if="getAmount(state, 'ALL') > 0" class="text-right slim-row">
                                {{ labels.accountingState[state] }}
                              </td>
                              <td v-for="method of labels.paymentMethods" :key="method" class="text-right slim-row"
                                  v-if="selectedPaymentMethods.includes(method) && getAmount(state, 'ALL') > 0">
                                {{ getAmount(state, method) | currency }}
                              </td>
                              <td v-if="getAmount(state, 'ALL') > 0" class="text-right slim-row">
                                <b>{{ getAmount(state, 'ALL') | currency }}</b></td>
                            </tr>
                            <tr :style="getAmountStyle('ALL')">
                              <td class="text-right slim-row">alle</td>
                              <td v-for="method of labels.paymentMethods" :key="method" class="text-right slim-row"
                                  v-if="selectedPaymentMethods.includes(method)">
                                <b>{{ getAmount('ALL', method) | currency }}</b></td>
                              <td class="text-right slim-row"><b>{{ getAmount('ALL', 'ALL') | currency }}</b></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br>
                      <div class="grid-x grid-padding-x">
                        <div class="cell small-12 medium-4">
                          <md-field v-if="!previewActive">
                            <label>Status ändern auf</label>
                            <md-select v-model="selectedBillingState" @md-selected="onChangeBillingStates"
                                       :disabled="sending || selectedAccountings.length <= 0">
                              <md-option v-for="accountingState of labels.accountingStates" :value="accountingState"
                                         :key="accountingState">
                                {{ labels.accountingState[accountingState] }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="cell small-12 medium-8">
                          <div v-if="sending">
                            <br>
                            <vue-simple-spinner></vue-simple-spinner>
                          </div>
                          <md-field v-else>
                            <label>Zahlungsweise</label>
                            <md-select v-model="selectedPaymentMethods" :disabled="sending"
                                       @md-selected="onSelectFilter" multiple>
                              <md-option v-for="paymentMethod of labels.paymentMethods" :value="paymentMethod"
                                         :key="paymentMethod">
                                {{ labels.paymentMethod[paymentMethod] }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </md-table-toolbar>

                <md-table-empty-state v-if="searchBill"
                                      md-label="Keine Abrechnung gefunden"
                                      :md-description="`Keine Abrechnung mit dem Suchbegriff '${searchBill}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
                </md-table-empty-state>

                <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-auto-select
                              :md-selectable="getSelectableOption(previewActive)" style="padding: 0;">
                  <md-table-cell style="padding: 0;" md-label="Nachname" md-sort-by="lastname">{{ item.lastname }}
                  </md-table-cell>
                  <md-table-cell style="padding: 0;" md-label="Vorname" md-sort-by="firstname">{{ item.firstname }}
                  </md-table-cell>
                  <md-table-cell style="padding: 0;" md-label="Zahlungsweise" md-sort-by="paymentMethod">
                    {{ labels.paymentMethod[item.paymentMethod] }}
                  </md-table-cell>
                  <md-table-cell style="padding: 0;" md-label="Status" md-sort-by="state">
                    {{ labels.accountingState[item.state] }}
                  </md-table-cell>
                  <md-table-cell style="padding: 0;" md-label="Gesamtbetrag" md-sort-by="totalAmount">
                    {{ item.totalAmount | currency }}
                  </md-table-cell>
                  <md-tooltip style="font-size: medium;" md-direction="left" md-delay="500">
                    <b>Kind</b><br>
                    <span>{{ item.fullname }}</span><br><br>
                    <b>Kontoinhaber</b><br>
                    <BankAccountView :bankAccount="item.accountInfo" :raw="true"/>
                    <br>
                    <b>Zahlungsweise:</b>&nbsp;<span>{{ labels.paymentMethod[item.paymentMethod] }}</span>
                    <span v-if="item.paymentMethod === 'SEPA'"> ({{labels.mandateType[item.accountInfo.mandateType] }})</span><br>
                    <b>Zahlungsinterval:</b>&nbsp;<span>{{labels.paymentInterval[item.paymentInterval] }}</span><br><br>
                    <b>{{ item.billingItems.length }}</b> Position<span
                      v-if="item.billingItems.length > 1">en</span><br>
                    <span v-for="(bi, index) of item.billingItems" :key="index">
                      {{ index + 1 }})&nbsp;
                      {{ bi.billingDate | moment("DD.MM.YYYY") }}&nbsp;
                      {{ labels.feeType[bi.feeType] }}&nbsp;
                      {{ bi.amount | currency }}&nbsp;
                      <b :class="item.state">{{ labels.accountingState[bi.state] }}</b>
                      <br></span><br>
                    <b>Gesamtbetrag:</b>&nbsp;<span>{{ item.totalAmount | currency }}</span>&nbsp;
                    <b :class="item.state">{{ labels.accountingState[item.state] }}</b>
                    <br>
                    <b>Gesamtnachlass:</b>&nbsp;<span>{{ item.totalDiscount | currency }}</span><br><br>
                    <b>Zahlungsziel:</b>&nbsp;<span>{{ item.billingDate | moment("DD.MM.YYYY") }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  </md-tooltip>
                </md-table-row>

              </md-table>

              <br>

              <div>
                <div v-if="previewActive" class="grid-x grid-padding-x">
                  <div class="cell small-12 medium-6">
                    <button @click="previewActive = false" class="button expanded" :disabled="sending">
                      <md-icon style="color: black; height: 13px; font-size: 1.3rem!important;">visibility_off</md-icon>&nbsp;&nbsp;&nbsp;Vorschau
                      beenden
                    </button>
                  </div>
                </div>
                <div v-else class="grid-x grid-padding-x">
                  <div class="cell small-6 medium-6 large-6">
                    <button @click="onGetInvoicesForChildren" class="button expanded"
                            :disabled="sending || selectedAccountings.length <= 0">
                      <i class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;<span
                        v-if="selectedAccountings.length > 0">{{ selectedAccountings.length }} </span>
                      PDF Rechnung<span v-if="selectedAccountings.length > 1">en</span>
                    </button>
                    <md-tooltip>
                      Rechnungen als PDF für <b>alle markierten</b> Kinder erstellen und herunterladen
                    </md-tooltip>
                  </div>
                  <div v-if="isBeta" class="cell small-6 medium-6 large-6">
                    <button @click="onSendInvoices4Children" class="button secondary expanded"
                            :disabled="sending || selectedAccountings.length <= 0">
                      <i class="fi-mail"></i>&nbsp;&nbsp;&nbsp;<span
                        v-if="selectedAccountings.length > 0">{{ selectedAccountings.length }} </span>
                      PDF Rechnung<span v-if="selectedAccountings.length > 1">en</span> als Email
                    </button>
                    <md-tooltip>
                      Rechnungen als PDF für <b>alle markierten</b> Kinder<br>
                      erstellen und <b>als Email</b>&nbsp;an die Eltern verschicken
                    </md-tooltip>
                  </div>
                  <div class="cell small-6 medium-6 large-6">
                    <button @click="onGetSepaXML" class="button success expanded" :disabled="sending || numOpenBillings <= 0">
                      <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;SEPA XML-Datei
                    </button>
                    <md-tooltip>
                      SEPA XML-Datei erstellen für <b>alle <span v-if="numOpenBillings > 0">{{ numOpenBillings }} </span>markierten</b> Kinder<br>mit Status <b>offen</b> und
                      hinterlegten <b>SEPA-Mandaten</b>
                    </md-tooltip>
                  </div>
                  <div class="cell small-6 medium-6 large-6">
                    <button @click="onGetAccountingOverview" class="button expanded warn"
                            :disabled="sending || selectedAccountings.length <= 0">
                      <i class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Abrechnungsübersicht
                    </button>
                    <md-tooltip>
                      Abrechnungsübersicht als PDF für <b>alle markierten</b> Kinder erstellen und herunterladen
                    </md-tooltip>
                  </div>
                </div>
              </div>

            </md-card-content>

          </md-card>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showCreateAccountingDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><img width="25px" src="../assets/icons/calculator.png"/>&nbsp;&nbsp;&nbsp;Abrechnung für
            <span class="group-title">{{ getMonthStr(selectedBill.month) }} {{ selectedYear }}</span>
            erstellen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Abrechnung für <b class="group-title">{{ getMonthStr(selectedBill.month) }}
              {{ selectedYear }}</b>
              und Einrichtung <b class="group-title">{{ selectedFacilityName }}</b> erstellt werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="createBilling" class="button success" :disabled="sending">
              <img width="14px" src="../assets/icons/calculator.png"/>&nbsp;&nbsp;&nbsp;Ja, Abrechnung erstellen
            </button>
            <button class="button alert" style="margin-left: 1rem;" @click="showCreateAccountingDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showCreateAccountingDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showPreviewAccountingDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <md-icon style="color: black; height: 13px; font-size: 1.3rem!important;">visibility</md-icon>&nbsp;&nbsp;&nbsp;Abrechnungsvorschau
            für
            <span class="group-title">{{ getMonthStr(selectedBill.month) }} {{ selectedYear }}</span>
            erstellen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Abrechnungsvorschau für <b class="group-title">{{ getMonthStr(selectedBill.month) }}
              {{ selectedYear }}</b>
              und Einrichtung <b class="group-title">{{ selectedFacilityName }}</b> erstellt werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="previewBilling" class="button success" :disabled="sending">
              <md-icon style="color: black; height: 13px; font-size: 1.3rem!important;">visibility</md-icon>&nbsp;&nbsp;&nbsp;Ja,
              Abrechnungsvorschau erstellen
            </button>
            <button class="button alert" style="margin-left: 1rem;" @click="showPreviewAccountingDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showPreviewAccountingDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteAllAccountingDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <md-icon>delete</md-icon>&nbsp;&nbsp;&nbsp;Abrechnung für
            <span class="group-title">{{ getMonthStr(selectedBill.month) }}  {{ selectedYear }}</span>
            löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Abrechnung für <b class="group-title">{{ getMonthStr(selectedBill.month) }}
              {{ selectedYear }}</b>
              und Einrichtung <b class="group-title">{{ selectedFacilityName }}</b> gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="onDeleteAllAccountingInformation" class="button alert" :disabled="sending">
              <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Abrechnung löschen
            </button>
            <button class="button success" style="margin-left: 1rem;" @click="showDeleteAllAccountingDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteAllAccountingDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showGetInvoicesForChildren">
        <div v-if="selectedAccountings.length > 0" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Rechnungen als PDF herunterladen
          </md-dialog-title>
          <div class="cell">
            <md-content v-if="progressCount === 0 && !sending">Soll<span
                v-if="selectedAccountings.length !== 1">en</span>&nbsp;<b
                class="group-title">{{ selectedAccountings.length }}</b>
              Rechnung<span v-if="selectedAccountings.length !== 1">en</span> für das Jahr <b
                  class="group-title">{{ selectedYear }}</b> als PDF heruntergeladen werden?

              <br><br>
              <div class="text-center">
                <md-radio v-model="singlePDF" value="true" class="md-primary">
                  Einzelnes PDF pro Rechnung
                </md-radio>
                <md-radio v-model="singlePDF" value="false" class="md-primary">
                  Alle Rechnungen in einem PDF
                </md-radio>
              </div>

            </md-content>

            <md-content v-else-if="progressCount === 0 && sending" class="text-center">
              Herunterladen wird vorbereitet ...
            </md-content>
            <md-content v-else class="text-center">
              <b class="group-title">{{ progressSuccessCount }}</b>&nbsp;Rechnung<span v-if="progressSuccessCount !==1">en</span>
              erfolgreich
              herunterladen<span v-if="progressFailureCount > 0">,&nbsp;<b
                class="group-title">{{ progressFailureCount }}</b> Downloads fehlgeschlagen</span>
            </md-content>

            <br>
            <md-progress-bar v-if="progress > 0" md-mode="determinate" :md-value="progress"></md-progress-bar>
            <md-progress-bar v-if="progressCount === 0 && sending" md-mode="indeterminate"></md-progress-bar>
          </div>
          <div v-if="progressCount === 0" class="cell text-center">
            <button @click="getInvoicesForChildren" class="button success" :disabled="sending"><i
                class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Ja,
              Rechnung<span v-if="selectedAccountings.length !== 1">en</span> herunterladen
            </button>
            <button class="button alert" style="margin-left: 1rem;" @click="showGetInvoicesForChildren = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <div v-else class="cell text-center">
            <button @click="showGetInvoicesForChildren = false" class="button success"
                    :disabled="sending || (progress < 100)"><i
                class="fi-check"></i>&nbsp;&nbsp;&nbsp;Fenster schliessen
            </button>
          </div>
          <button class="close-button" type="button" @click="showGetInvoicesForChildren = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showSendInvoices4Children">
        <div v-if="selectedAccountings.length > 0" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-mail"></i>&nbsp;&nbsp;&nbsp;Rechnungen per Email versenden
          </md-dialog-title>
          <div class="cell">
            <md-content v-if="progressCount === 0 && !sending">Soll<span
                v-if="selectedAccountings.length !== 1">en</span>&nbsp;<b
                class="group-title">{{ selectedAccountings.length }}</b>
              Rechnung<span v-if="selectedAccountings.length !== 1">en</span> für das Jahr <b
                  class="group-title">{{ selectedYear }}</b> per Email versendet werden?
            </md-content>

            <md-content v-else-if="progressCount === 0 && sending" class="text-center">
              Versenden wird vorbereitet ...
            </md-content>
            <md-content v-else class="text-center">
              <b class="group-title">{{ progressSuccessCount }}</b>&nbsp;Rechnungen erfolgreich per Email
              versendet<span v-if="progressFailureCount > 0">,&nbsp;<b
                class="group-title">{{ progressFailureCount }}</b> Email-Sendungen fehlgeschlagen</span>
            </md-content>

            <br>
            <md-progress-bar v-if="progress > 0" md-mode="determinate" :md-value="progress"></md-progress-bar>
          </div>
          <div v-if="progressCount === 0" class="cell text-center">
            <button @click="sendInvoices4Children" class="button success" :disabled="sending"><i
                class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Ja,
              Rechnung<span v-if="selectedAccountings.length !== 1">en</span> versenden
            </button>
            <button class="button alert" style="margin-left: 1rem;" @click="showSendInvoices4Children = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <div v-else class="cell text-center">
            <button @click="showSendInvoices4Children = false" class="button success"
                    :disabled="sending || (progressCount < selectedAccountings.length)"><i
                class="fi-check"></i>&nbsp;&nbsp;&nbsp;Fenster schliessen
            </button>
          </div>
          <button class="close-button" type="button" @click="showSendInvoices4Children = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <ListOfClaimsDialog :facilityReferenceNumber="selectedFacilityReferenceNumber"
                          :facilityName="selectedFacilityName"
                          :listOfClaims="listOfClaims"
                          :year="selectedYear"
                          :showDialog="showListOfClaimsDialog"
                          @closeListOfClaimsDialog="showListOfClaimsDialog = false"></ListOfClaimsDialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="FINANCE" moduleFunction="Abrechnungen"></NoModuleCard>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import Vue from 'vue';
import Spinner from 'vue-simple-spinner';
import BillingService from '../services/BillingService';
import ChildService from '../services/ChildService';
import PdfService from '../services/PdfService';
import HttpErrorHandler from '../services/HttpErrorHandler';
import BankAccountView from '../components/views/BankAccountView';
import ImageView from '../components/views/ImageView';
import {validationMixin} from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import DoughnutChart from '../components/charts/DoughnutChart';
import BarChart from '../components/charts/BarChart';
import NoFacilityCard from "../components/cards/NoFacilityCard";
import ListOfClaimsDialog from "../components/dialogs/ListOfClaimsDialog";
import NoModuleCard from '../components/cards/NoModuleCard';

const toLower = text => {
  return text.toString().toLowerCase();
};

const searchBillByChildName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.fullname).includes(toLower(term)));
  }

  return items;
};

const filterBillByPaymentmethod = (items, selectedFilter) => {
  return items.filter(item => {
    if (selectedFilter.includes('CASH') && item.paymentMethod.includes('CASH')) {
      return true;
    } else if (selectedFilter.includes('SEPA') && item.paymentMethod.includes('SEPA')) {
      return true;
    } else if (selectedFilter.includes('TRANSFER') && item.paymentMethod.includes('TRANSFER')) {
      return true;
    } else {
      return false;
    }
  });
};

export default {
  name: 'Billings',
  mixins: [validationMixin],

  components: {
    ListOfClaimsDialog,
    NoFacilityCard,
    BankAccountView,
    ImageView,
    'vue-simple-spinner': Spinner,
    DoughnutChart,
    BarChart,
    NoModuleCard,
  },

  validations: {
    billingDate: {
      required,
    },
  },

  mounted() {
    this.restoreUserSettings();
    this.reloadAccounting();

    HttpErrorHandler.maintainDarkMode(this);
  },

  beforeDestroy() {
    this.cleanPDFs;
    this.cleanXMLs();
    this.xmlUrl = null;
    this.initDatePickerSyncHandler(false);
  },

  data() {
    return {
      datePickerSyncHandlerInitialized: false,

      sending: false,
      progress: 0,
      progressCount: 0,
      progressSuccessCount: 0,
      progressFailureCount: 0,
      billings: [],
      accountings: [],
      searchedAccounting: [],
      searched: [],
      searchBill: '',
      search: null,
      selected: null,
      selectedRows: [],
      selectedYear: Vue.moment().format("YYYY"),
      billingYears: [],

      billingDate: Vue.moment().format('DD.MM.YYYY'),
      billingDateInvalid: false,
      showCreateAccountingDialog: false,
      showPreviewAccountingDialog: false,

      successMsg: '',
      selectedBill: null,
      availableFacilities: [],
      allowedFacilities: [],
      selectedFacilityReferenceNumber: null,
      selectedFacilityName: '',

      selectedRowId: 0,
      selectedBillingState: '',
      selectedAccountings: [],

      responseCount: 0,

      showDeleteAllAccountingDialog: false,
      showGetInvoicesForChildren: false,
      showSendInvoices4Children: false,
      showListOfClaimsDialog: false,

      listOfClaims: [],

      billingBarChartData: null,
      billingBarChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        layout: {
          padding: {
            bottom: 10,
          }
        },
      },

      amountsPerTypeDoughnutChartData: null,
      amountsPerTypeDoughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'right',
        },
        layout: {
          padding: {
            bottom: 10,
          }
        },
        tooltips: {
          callbacks: {
            title: function () {
              return '';
            },
            label: function (tooltipItem, data) {
              return ' ' + data.labels[tooltipItem.index];
            }
          }
        }
      },

      xmlUrl: null,
      xmlLink: null,
      xmlBlob: null,

      singlePDF: 'true',
      pdfLoading: false,
      pdfUrl: null,
      pdfLinks: [],
      pdfBlobs: [],

      selectedPaymentMethods: [],
      previewActive: false,
      previewTotalAmount: 0,
    }
  },

  methods: {

    getAmount(state, method) {
      let amount = 0;
      this.searchedAccounting.forEach(accounting => {
        if ((accounting.paymentMethod === method || method === 'ALL') &&
            (accounting.state === state || state === 'ALL')) {
          amount += accounting.totalAmount;
        }
      });
      return amount;
    },

    getAmountStyle(state) {
      let colorSet = this.getBarChartColor4Type(state);
      return 'color: ' + colorSet + ';';
    },

    getSelectableOption(preview) {
      return preview ? 'single' : 'multiple'
    },

    onSelectFilter() {
      this.searchOnAccountingTable();
    },

    initDatePickerSyncHandler(init) {
      if (init) {
        if (!this.datePickerSyncHandlerInitialized) {
          setTimeout(() => {
            jQuery('#billing-date-dp').children('input').bind('blur', () => {
              this.billingDate = jQuery('#billing-date-dp').children('input').val();
            })
          }, 500);
          this.datePickerSyncHandlerInitialized = true;
        }
      } else {
        jQuery('#billing-date-dp').children('input').unbind('blur');
        this.datePickerSyncHandlerInitialized = false;
      }
    },

    restoreUserSettings() {
      this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
      if (this.selectedFacilityReferenceNumber === '*') {
        this.selectedFacilityReferenceNumber = null;
      }
    },

    initData() {
      if (this.billingYears.length <= 0) {
        if (Vue.moment().isAfter(Vue.moment('01.11.' + Vue.moment().format('YYYY'), 'DD.MM.YYYY'))) {
          this.billingYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) + 1), 'DD-MM-YYYY').format('YYYY'));
        }
        for (let i = 0; i < 11; i++) {
          this.billingYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) - i), 'DD-MM-YYYY').format('YYYY'));
        }
      }
    },

    preSelectAccounting() {
      if (localStorage.getItem(this.user.md5 + '@selectedAccountingMonth')) {
        this.selectedRowId = localStorage.getItem(this.user.md5 + '@selectedAccountingMonth');
        localStorage.removeItem(this.user.md5 + '@selectedAccountingMonth');
      }
    },

    buildCharts() {
      this.buildBillingChart();
      this.buildAmountsPerTypeChart();
    },

    buildBillingChart() {
      // Chartjs
      let datasets = [];
      let labels = [];

      for (let item of this.searched) {
        labels.push(this.$material.locale.shortMonths[item.month - 1]);
      }

      let data = [];
      for (let item of this.searched) {
        data.push(item.canceledItems);
      }
      datasets.push({
        label: this.labels.billingState['CANCELED'],
        backgroundColor: this.getBarChartColor4Type('CANCELED'),
        data: data,
      });

      data = [];
      for (let item of this.searched) {
        data.push(item.unpayedItems);
      }
      datasets.push({
        label: this.labels.billingState['UNPAYED'],
        backgroundColor: this.getBarChartColor4Type('UNPAYED'),
        data: data,
      });

      data = [];
      for (let item of this.searched) {
        data.push(item.payedItems);
      }
      datasets.push({
        label: this.labels.billingState['PAYED'],
        backgroundColor: this.getBarChartColor4Type('PAYED'),
        data: data,
      });

      data = [];
      for (let item of this.searched) {
        data.push(item.openItems);
      }
      datasets.push({
        label: this.labels.billingState['OPEN'],
        backgroundColor: this.getBarChartColor4Type('OPEN'),
        data: data,
      });

      this.billingBarChartData = {
        labels: labels,
        datasets: datasets,
      };
    },

    getColor4Type(type) {
      let colorSet = [
        {type: 'UNDEFINED', color: '#AAAAAA'},
        {type: 'ADMISSION_FEE', color: '#ff6384'},
        {type: 'MEMBERSHIP_FEE', color: '#ff9f40'},
        {type: 'UNPAYED', color: '#ffcd56'},
        {type: 'CANCELED', color: '#4bc0c0'},
        {type: 'CARE_MONEY', color: '#36a2eb'},
        {type: 'PAYED', color: '#146ebd'},
        {type: 'OPEN', color: '#ccb2ff'},
        {type: 'OFFICIAL_GRANT', color: '#ffcf9f'},
        {type: 'MISC_DISCOUNT', color: '#42ea50'},
        {type: 'MISC_PAYMENT', color: '#9966ff'},
        {type: 'FOOD_MONEY', color: '#4bc0c0'},
        {type: 'TRIP_MONEY', color: '#c04ba6'},
        {type: 'DIAPER_MONEY', color: '#c0674b'},
      ];
      for (let i in colorSet) {
        if (colorSet[i].type === type) {
          return colorSet[i].color;
        }
      }
      return colorSet[0].color;
    },

    getBarChartColor4Type(type) {
      let colorSet = [
        {type: 'UNDEFINED', color: '#AAAAAA'},
        {type: '', color: '#efa7b3'},
        {type: '', color: '#ff6384'},
        {type: '', color: '#f44336'},
        {type: '', color: '#ff9f40'},
        {type: 'OPEN', color: '#ffcd56'},
        {type: '', color: '#009688'},
        {type: 'UNPAYED', color: '#e91e63'},
        {type: '', color: '#4bc0c0'},
        {type: 'CANCELED', color: '#36a2eb'},
        {type: '', color: '#146ebd'},
        {type: '', color: '#ccb2ff'},
        {type: '', color: '#ffcf9f'},
        {type: 'PAYED', color: '#42ea50'},
        {type: 'SETTLED', color: '#42ea50'},
        {type: 'U3kinder', color: '#8fbc8f'},
        {type: '', color: '#9966ff'},
        {type: '', color: '#4bc0c0'},
        {type: 'ALL', color: '#666666'},
        {type: '', color: '#c04ba6'},
        {type: '', color: '#c0674b'},
        {type: '', color: '#795548'},
      ];
      for (let i in colorSet) {
        if (colorSet[i].type === type) {
          return colorSet[i].color;
        }
      }
      return colorSet[0].color;
    },

    buildAmountsPerTypeChart() {
      if (this.selectedBill) {

        let data = [];
        Object.values(this.selectedBill.totalMonthlyAmountsPerType).forEach((value) => data.push(value));
        let labels = [];
        let colors = [];
        Object.entries(this.selectedBill.totalMonthlyAmountsPerType).forEach((entry) => {
          labels.push(this.$options.filters.currency(entry[1]) + ' ' + this.labels.feeType[entry[0]]);
          colors.push(this.getColor4Type(entry[0]));
          // colors.push(this.getGradientColor4Types(entry[0]));
        });

        this.amountsPerTypeDoughnutChartData = {
          datasets: [{
            data: data,
            backgroundColor: colors,
            borderWidth: 0,
          }],
          labels: labels,
        };
      }
    },

    getValidationClass(fieldName) {
      let field = this.$v[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    onCloseBillingDatePicker() {
      this.validateBillingDate();
    },

    validateBillingDate() {
      this.$v.$touch();
      this.billingDateInvalid = this.$v.$invalid;
    },

    searchOnAccountingTable() {
      this.searchedAccounting = searchBillByChildName(this.accountings, this.searchBill);
      this.searchedAccounting = filterBillByPaymentmethod(this.searchedAccounting, this.selectedPaymentMethods);

      localStorage.setItem(this.user.md5 + '@searchedAccounting', JSON.stringify(this.searchedAccounting));
      localStorage.setItem(this.user.md5 + '@selectedPaymentMethods', JSON.stringify(this.selectedPaymentMethods));
      localStorage.setItem(this.user.md5 + '@accountingTitle', 'Abrechnung <b>' + this.getMonthStr(this.selectedBill.month) + ' ' + this.selectedYear + '</b> ' + this.selectedFacilityName);
    },

    buildAvailableFacilities() {
      this.availableFacilities = this.getAvailableFacilities;
      for (let i = 0; i < this.availableFacilities.length; i++) {
        if (this.availableFacilities[i].referenceNumber === '*') {
          this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
        }
      }
      if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
        this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
        this.selectedFacilityName = this.availableFacilities[0].name;
      }
      let i = 0;
      for (let facility of this.availableFacilities) {
        if (i > 0) {
          this.allowedFacilities.push(facility);
        }
        i++;
      }
      for (let fac of this.availableFacilities) {
        if (fac.referenceNumber === this.selectedFacilityReferenceNumber) {
          this.selectedFacilityName = fac.name;
        }
      }
      this.initData();
      this.preSelectAccounting();
    },

    reloadAccounting() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {
          this.selectedPaymentMethods = [];
          Object.keys(this.labels.paymentMethod).forEach(key => this.selectedPaymentMethods.push(key));
          if (this.isAdmin || this.isTreasurer) {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.buildAvailableFacilities();
            this.getAllOrganizationBillingInformation4Year();
          } else {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.buildAvailableFacilities();
          }

          clearInterval(reloadIntervalId);
          jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 605px');
        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    getAllOrganizationBillingInformation4Year() {
      if (this.selectedFacilityReferenceNumber) {
        this.getAllOrganizationFacilityBillingInformation4Year(this.selectedFacilityReferenceNumber);
      }
    },

    getAllOrganizationFacilityBillingInformation4Year(referenceNumber) {
      if (!this.facilitiesNotExists) {
        this.sending = true;
        BillingService.getAccountingInformation4Year(referenceNumber, this.selectedYear)
            .then(response => {
              this.billings = response.data;
              this.searchOnTable();
              this.sending = false;
              setTimeout(() => jQuery('#' + this.selectedRowId).trigger('click'), 250);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Abrechnung für Einrichtung mit Nummer '
                  + referenceNumber + ' und Jahr ' + this.selectedYear);
              this.sending = false;
            })
      }
    },

    getAccountingForYearAndMonth(referenceNumber, month) {
      this.sending = true;
      BillingService.getAccountingForYearAndMonth(referenceNumber, this.selectedYear, month)
          .then(response => {
            this.accountings = response.data;
            this.sending = false;
            this.searchOnAccountingTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Abrechnung für Einrichtung mit Nummer '
                + referenceNumber + ' und Jahr ' + this.selectedYear + ' und Monat ' + month);
            this.sending = false;
          })
    },

    searchOnTable() {
      this.searched = this.billings;
      this.buildCharts();
    },

    getClass: ({id}) => ({
      'md-primary': id === id
    }),

    onSelect(item) {
      this.previewActive = false;
      if (item && item.month) {
        this.selectedRowId = item.month;
        this.selected = JSON.parse(JSON.stringify(item));
        this.selectedBill = JSON.parse(JSON.stringify(this.selected));

        localStorage.setItem(this.user.md5 + '@selectedAccountingMonth', item.month);

        this.billingDate = Vue.moment(item.month + '-01-' + this.selectedYear, 'MM-DD-YYYY').format('DD.MM.YYYY');
        this.buildAmountsPerTypeChart();
        this.getAccountingForYearAndMonth(this.selectedFacilityReferenceNumber, this.selectedBill.month);
        this.initDatePickerSyncHandler(true);
      } else {
        this.selected = null;
        this.selectedBill = null;
        this.accountings = [];
        this.searchedAccounting = [];
      }
    },

    onSelectFacility() {
      if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

        localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

        for (let fac of this.availableFacilities) {
          if (fac.referenceNumber === this.selectedFacilityReferenceNumber) {
            this.selectedFacilityName = fac.name;
          }
        }

        this.selected = null;
        this.selectedBill = null;

        if (this.selectedFacilityReferenceNumber === '*') {
          this.getAllOrganizationBillingInformation4Year();
        } else {
          this.getAllOrganizationFacilityBillingInformation4Year(this.selectedFacilityReferenceNumber);
        }
      }
    },

    onBillingYear() {
      this.onSelectFacility();
    },

    getMonthStr(month) {
      return Vue.moment(month + '-01-' + this.selectedYear, 'MM-DD-YYYY').format('MMMM');
    },

    createAccounting4YearAndMonth(referenceNumber, month, date, preview) {
      this.sending = true;
      BillingService.createAccounting4YearAndMonth(referenceNumber, this.selectedYear, month, date, preview)
          .then(response => {
            this.accountings = response.data;
            this.successMsg = 'Die Abrechnung';
            if (preview) {
              this.successMsg += 'svorschau';
            }
            this.successMsg += ' für&nbsp;<b>' + this.getMonthStr(month) + ' ' + this.selectedYear + ' (' + Vue.moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') + ')</b>&nbsp;';
            if (this.accountings.length <= 0) {
              this.successMsg += 'enthält keine Kinder mit Gebühren.'
            } else {
              this.successMsg += 'wurde erfolgreich erstellt.';
            }
            this.$store.commit('successMsg', this.successMsg);

            this.sending = false;
            this.showCreateAccountingDialog = false;
            this.showPreviewAccountingDialog = false;

            this.previewActive = preview;

            if (preview) {
              this.previewTotalAmount = 0;
              this.accountings.forEach(ac => this.previewTotalAmount += ac.totalAmount);
              this.searchOnAccountingTable();
            } else {
              this.reloadAccounting();
            }
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erstellen der Abrechnung für&nbsp;<b>' +
                this.getMonthStr(month) + ' ' + this.selectedYear + ' (' +
                Vue.moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') + ')</b>');
            this.sending = false;
          })
    },

    createBilling() {
      if (this.selectedBill && !this.selectedBill.accountingExists) {
        this.createAccounting4YearAndMonth(
            this.selectedFacilityReferenceNumber, this.selectedBill.month,
            Vue.moment(this.billingDate, "DD.MM.YYYY").format('YYYY-MM-DD'), false);
      }
    },

    onCreateBilling() {
      if (this.selectedBill && !this.selectedBill.accountingExists) {
        this.showCreateAccountingDialog = true;
      }
    },

    previewBilling() {
      if (this.selectedBill && !this.selectedBill.accountingExists) {
        this.createAccounting4YearAndMonth(
            this.selectedFacilityReferenceNumber, this.selectedBill.month,
            Vue.moment(this.billingDate, "DD.MM.YYYY").format('YYYY-MM-DD'), true);
      }
    },

    onPreviewBilling() {
      if (this.selectedBill && !this.selectedBill.accountingExists) {
        this.showPreviewAccountingDialog = true;
      }

    },

    onSelectAccountings(items) {
      if (items) {
        this.selectedAccountings = JSON.parse(JSON.stringify(items));
      } else {
        this.selectedAccountings = [];
        this.selectedBillingState = '';
      }
    },

    onChangeBillingStates() {
      if (this.labels.accountingStates.includes(this.selectedBillingState)) {
        this.responseCount = 0;
        for (let billingDetail of this.selectedAccountings) {
          this.setAccountingState(billingDetail.id, this.selectedBillingState, this.selectedAccountings.length);
        }
      }
    },

    setAccountingState(accountingId, state, numRequests) {
      this.sending = true;
      BillingService.setAccountingState(accountingId, state)
          .then(() => {
            this.responseCount++;
            if (this.responseCount >= numRequests) {
              this.sending = false;

              this.successMsg = 'Statusänderung für&nbsp;<b>' + this.selectedAccountings.length + ' Abrechnung';
              if (this.selectedAccountings.length > 1) {
                this.successMsg += 'en';
              }
              this.successMsg += '</b>&nbsp;erfolgreich durchgeführt.';
              this.$store.commit('successMsg', this.successMsg);

              setTimeout(() => {
                this.searchedAccounting = [...this.searchedAccounting];
                this.selectedBillingState = '';
                this.reloadAccounting();
              }, 250);
            }
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Setzen des Status ' + state + ' für Abrechnung mit Id ' + accountingId);
            this.sending = false;
          })
    },

    onSendInvoices4Children() {
      this.progress = 0;
      this.progressCount = 0;
      this.progressSuccessCount = 0;
      this.progressFailureCount = 0;
      this.showSendInvoices4Children = true;
    },

    sendInvoices4Children() {
      let childIds = [];
      for (let accounting of this.selectedAccountings) {
        childIds.push(accounting.childId);
      }
      this.sendInvoices4SelectedChildren(this.selectedFacilityReferenceNumber, childIds);
    },

    sendInvoices4SelectedChildren(referenceNumber, childIds) {
      this.sending = true;
      ChildService.sendInvoices4Children(childIds, this.selectedYear, this.selectedRowId)
          .then(() => {
            setTimeout(() => {
              this.progress += (100 / this.selectedAccountings.length);
              this.progressCount += childIds.length;
              this.progressSuccessCount += childIds.length;

              this.sending = false;
              this.progressCount = this.selectedAccountings.length;
              this.progress = 100;
              this.showSendInvoiceEndMessage();
            }, 250);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Senden per Email von ' + childIds.length + ' Rechnungen für Monat ' + this.selectedRowId + ' und Jahr ' + this.selectedYear);
            this.progressCount = this.selectedAccountings.length;
            this.progressFailureCount += childIds.length;
            this.progress = 100;
            this.sending = false;
            this.showSendInvoiceEndMessage();
          })
    },

    showSendInvoiceEndMessage() {
      if (this.progressCount >= this.searchedAccounting.length) {
        this.$store.commit('successMsg', this.progressSuccessCount + ' Rechnungen erfolgreich versendet.');
        if (this.progressFailureCount > 0) {
          HttpErrorHandler.handleError(null, this, this.progressFailureCount + ' Rechnungen konnten nicht versendet werden');
        }
      }
    },

    onGetInvoicesForChildren() {
      this.progress = 0;
      this.progressCount = 0;
      this.progressSuccessCount = 0;
      this.progressFailureCount = 0;
      this.showGetInvoicesForChildren = true;
    },

    getInvoicesForChildren() {
      this.cleanPDFs();
      let childrenConfig = [];
      for (let accounting of this.selectedAccountings) {
        let childConfig = {
          facilityReference: this.selectedFacilityReferenceNumber,
          childId: accounting.childId,
          dsgvoPersonId: null,
          year: this.selectedYear,
          month: this.selectedRowId,
          template: {
            type: 'INVOICE',
            content: '',
            name: '',
            standard: false,
            printMargins: {
              top: 0,
              right: 0,
              left: 0,
              bottom: 0
            },
            forceUseOfLetterPaper: 0,
          },
          preview: false,
        }
        if (this.singlePDF === 'true') {
          this.getInvoiceTemplateDataForChild(this.selectedFacilityReferenceNumber, [childConfig])
        } else {
          childrenConfig.push(childConfig);
        }
      }
      if (this.singlePDF !== 'true') {
        this.getInvoiceTemplateDataForChild(this.selectedFacilityReferenceNumber, childrenConfig)
      }
    },

    getInvoiceTemplateDataForChild(referenceNumber, config) {
      this.sending = true;
      PdfService.getTemplateData(config)
          .then(response => {
            this.sending = false;
            this.createInvoicePdfForChild(referenceNumber, response.data)
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für ' + config.length + ' Kind(er) zur Erstellung der Rechnungen für Monat ' + this.selectedRowId + ' und Jahr ' + this.selectedYear);
            this.sending = false;
          })
    },

    createInvoicePdfForChild(referenceNumber, template) {
      this.sending = true;
      PdfService.createPdf(template)
          .then(response => {
            setTimeout(() => {
              this.progress += (100 * (template.length / this.selectedAccountings.length));
              this.progressCount++;
              this.progressSuccessCount++;

              this.pdfBlobs.push(new Blob([response.data, 'utf-8'], {type: "application/pdf"}));
              this.pdfUrl = window.URL.createObjectURL(this.pdfBlobs[this.pdfBlobs.length - 1]);

              this.pdfLinks.push(document.createElement('a'));
              let id = this.organization.name + ' (' + referenceNumber + ') Rechnung ' + this.selectedYear + '-' + this.selectedRowId + ' ';
              if (template.length === 1) {
                id += template[0].child.masterdata.lastname + ' ';
                id += template[0].child.masterdata.firstname;
              } else {
                id += '(' + template.length + ' Kinder)';
              }
              this.pdfLinks[this.pdfLinks.length - 1].href = this.pdfUrl;
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('download', id + '.pdf');
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('id', id);
              document.body.appendChild(this.pdfLinks[this.pdfLinks.length - 1]);
              this.pdfLinks[this.pdfLinks.length - 1].click();

              this.sending = false;
              this.showDownloadInvoiceEndMessage();
            }, 250);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Rechnungen für ' + template.length + ' Kinder und Monat' + this.selectedRowId + ' und Jahr ' + this.selectedYear);
            this.progressCount++;
            this.progressFailureCount++;
            this.sending = false;
            this.showDownloadInvoiceEndMessage();
          })
    },

    showDownloadInvoiceEndMessage() {
      if (this.progressCount >= this.searchedAccounting.length) {
        this.$store.commit('successMsg', this.progressSuccessCount + ' Dokument(e) erfolgreich herunterladen.');
        if (this.progressFailureCount > 0) {
          HttpErrorHandler.handleError(null, this, this.progressFailureCount + ' Dokument(e) konnten nicht herunterladen werden');
        }
      }
    },

    cleanPDFs() {
      if (this.pdfUrl) {
        this.pdfUrl = window.URL.revokeObjectURL(this.pdfUrl);
        for (let i = 0; i < this.pdfBlobs.length; i++) {
          this.pdfBlobs[i] = null;
        }
        while (this.pdfBlobs.length > 0) {
          this.pdfBlobs.pop();
        }
        this.pdfBlobs = [];
      }

      for (let i = 0; i < this.pdfLinks.length; i++) {
        document.body.removeChild(this.pdfLinks[i]);
        this.pdfLinks[i] = null;
      }
      while (this.pdfLinks.length > 0) {
        this.pdfLinks.pop();
      }
      this.pdfLinks = [];
    },

    onGetAccountingOverview() {
      this.cleanPDFs();
      let childrenConfig = [];
      for (let accounting of this.selectedAccountings) {
        let childConfig = {
          facilityReference: this.selectedFacilityReferenceNumber,
          childId: accounting.childId,
          dsgvoPersonId: null,
          year: this.selectedYear,
          month: this.selectedRowId,
          template: {
            type: 'ACCOUNTINGOVERVIEW',
            content: '',
            name: '',
            standard: false,
            printMargins: {
              top: 0,
              right: 0,
              left: 0,
              bottom: 0
            },
            forceUseOfLetterPaper: 0,
          },
          preview: false,
        }
        childrenConfig.push(childConfig);
      }
      this.getAccountingOverviewTemplateData(this.selectedFacilityReferenceNumber, childrenConfig)
    },

    getAccountingOverviewTemplateData(referenceNumber, config) {
      this.sending = true;
      PdfService.getTemplateData(config)
          .then(response => {
            this.sending = false;
            this.createAccountingOverviewPdf(referenceNumber, response.data)
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für ' + config.length + ' Kind(er) zur Erstellung der Abrechnungsübersicht für Monat ' + this.selectedRowId + ' und Jahr ' + this.selectedYear);
            this.sending = false;
          })
    },

    createAccountingOverviewPdf(referenceNumber, template) {
      this.sending = true;
      PdfService.createPdf(template)
          .then(response => {
            setTimeout(() => {
              this.pdfBlobs.push(new Blob([response.data, 'utf-8'], {type: "application/pdf"}));
              this.pdfUrl = window.URL.createObjectURL(this.pdfBlobs[this.pdfBlobs.length - 1]);

              this.pdfLinks.push(document.createElement('a'));
              let id = this.organization.name + ' (' + referenceNumber + ') Abrechnungsübersicht ' + this.selectedYear + '-' + this.selectedRowId + ' ';
              this.pdfLinks[this.pdfLinks.length - 1].href = this.pdfUrl;
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('download', id + '.pdf');
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('id', id);
              document.body.appendChild(this.pdfLinks[this.pdfLinks.length - 1]);
              this.pdfLinks[this.pdfLinks.length - 1].click();

              this.sending = false;
              this.$store.commit('successMsg', ' Die Abrechnungsübersicht für Monat' + this.selectedRowId + ' und Jahr ' + this.selectedYear + ' wurde erfolgreich herunterladen.');
            }, 250);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der Abrechnungsübersicht für ' + template.length + ' Kind(er) und Monat' + this.selectedRowId + ' und Jahr ' + this.selectedYear);
            this.progressCount++;
            this.progressFailureCount++;
            this.sending = false;
          })
    },

    onGetSepaXML() {
      let childIds = [];
      this.selectedAccountings.forEach(accounting => {
       if (accounting.state === 'OPEN') {
         childIds.push(accounting.childId)
       }
      });
      this.getSepaXML4YearAndMonth(this.selectedBill.month, childIds);
    },

    cleanXMLs() {
      if (this.xmlUrl) {
        this.xmlBlob = null;
        document.body.removeChild(this.xmlLink);
        this.xmlLink = null;
      }
    },

    getSepaXML4YearAndMonth(month, childIds) {
      this.sending = true;
      BillingService.getSepaXML4YearAndMonth(this.selectedFacilityReferenceNumber, this.selectedYear, month, childIds)
          .then(response => {
            setTimeout(() => {
              this.cleanXMLs();

              this.xmlBlob = new Blob([response.data]);
              this.xmlUrl = window.URL.createObjectURL(this.xmlBlob);
              this.xmlLink = document.createElement('a');
              const id = this.selectedFacilityName + ' (' + this.selectedFacilityReferenceNumber + ') SEPA Daten ' + this.getMonthStr(month) + ' ' + this.selectedYear;
              this.xmlLink.href = this.xmlUrl;
              this.xmlLink.setAttribute('download', id + '.xml');
              this.xmlLink.setAttribute('id', id);
              document.body.appendChild(this.xmlLink);
              this.xmlLink.click();

              this.sending = false;
              this.$store.commit('successMsg', 'SEPA XML-Datei für Einrichtung ' + this.selectedFacilityName +
                  '(' + this.selectedFacilityReferenceNumber + ') und Jahr ' + this.selectedYear +
                  ' und Monat ' + this.getMonthStr(month) + ' wurde erfolgreich erzeugt.');
            }, 500);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der SEPA XML-Datei für Einrichtung ' + this.selectedFacilityName +
                '(' + this.selectedFacilityReferenceNumber + ') und Jahr ' + this.selectedYear + ' und Monat ' + this.getMonthStr(month));
            this.sending = false;
          })
    },

    deleteAllAccountingInformation(referenceNumber, month) {
      this.sending = true;
      BillingService.deleteAccountingInformation(referenceNumber, this.selectedYear, month)
          .then(() => {
            this.$store.commit('successMsg', 'Die Abrechnung für&nbsp;<b>' + this.getMonthStr(month) + ' ' +
                this.selectedYear + '</b>&nbsp;wurde erfolgreich gelöscht.');
            this.sending = false;
            this.showDeleteAllAccountingDialog = false;
            this.reloadAccounting();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Abrechnung für&nbsp;<b>' +
                this.getMonthStr(month) + ' ' + this.selectedYear + '</b>');
            this.sending = false;
          })
    },

    onDeleteAllAccountingInformation() {
      this.deleteAllAccountingInformation(this.selectedFacilityReferenceNumber, this.selectedBill.month);
    },

    deleteAllAccountingDialog() {
      this.showDeleteAllAccountingDialog = true;
    },

    onOpenListOfClaimsDialog() {
      this.getListOfClaims(this.selectedYear);
    },

    getListOfClaims(year) {
      this.sending = true;
      BillingService.getListOfClaims(this.selectedFacilityReferenceNumber, year)
          .then(response => {
            this.sending = false;
            this.listOfClaims = response.data;
            this.showListOfClaimsDialog = true;
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Forderungsliste für Einrichtung ' + this.selectedFacilityName +
                '(' + this.selectedFacilityReferenceNumber + ') und Jahr ' + year);
          })
    },

  },

  computed: {

    numOpenBillings() {
      let numOpenBillings = 0;
      this.selectedAccountings.forEach(accounting => {
        if (accounting.state === 'OPEN') {
          numOpenBillings++;
        }
      });

      return numOpenBillings;
    },

    facilitiesNotExists() {
      return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
    },

    dataAvailable() {
      return this.labels && this.organization && this.organization.facilities && this.user &&
          this.user.allowedFacilities && this.user.allowedFacilities.length > 0 &&
          this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isTreasurer() {
      return this.$store.getters.treasurer;
    },

    isBeta() {
      return this.$store.getters.beta;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isFacility() {
      return this.$store.getters.facility;
    },

    isManagement() {
      return this.$store.getters.management;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    canWrite() {
      return this.$store.getters.canWrite;
    },

    user() {
      return this.$store.getters.user;
    },

    organization() {
      return this.$store.getters.organization;
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },

    labels() {
      return this.$store.getters.labels
    },

    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },
  }
}
</script>

<style lang="scss" scoped>

.md-dialog /deep/ .md-dialog-container {
  max-width: 800px;
}

p {
  margin-bottom: 5px;
}

h5 {
  font-weight: 400;
  color: lightseagreen;
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
}

.billing-title {
  color: chocolate;
  font-weight: bold;
  font-size: larger;
}

.md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
  background: darkseagreen;
}

.md-sub-title {
  color: lightseagreen;
  font-weight: normal;
  font-size: 0.9rem;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
}

.bill-title {
  color: chocolate;
  font-weight: normal;
  font-size: 1.8rem;
}

.bill-sub-title {
  color: cornflowerblue;;
  font-weight: normal;
  font-size: 1.2rem;
}

.OPEN {
  color: yellow;
}

.SETTLED {
  color: greenyellow;
}

.PAYED {
  color: greenyellow;
}

.PARTLY_SETTLED {
  color: orange;
}

.UNPAYED {
  color: orangered;
}

.CANCELED {
  color: deepskyblue;
}

table {
  border-collapse: unset;
}

tbody th, tbody td, thead th, thead td, {
  padding: 0;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: transparent;
}
</style>
